<template>
    <v-dialog v-model="shown" v-on="$listeners" v-bind="dialogAttrs">
        <template #activator="{ on, attrs }">
            <slot name="activator" v-bind="{ on, attrs }" />
        </template>
        <v-card>
            <v-overlay v-if="overlay" :value="overlay.shown" v-bind="overlay.attrs || {}">
                <slot name="overlay" />
            </v-overlay>

            <v-card-title>
                <slot name="title" />
            </v-card-title>

            <slot name="body" />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    v-for="btn,i in buttons"
                    :key="`dialog-${name}-action-${i}`"
                    v-on="btn.on"
                    v-bind="btn.attrs"
                    v-text="btn.label"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { generateRandomString } from '@/lib/utils'

export default {
    data: () => ({
        shown: false,
    }),
    methods: {
        show() { this.shown = true; },
        hide() { this.shown = false; }
    },
    computed: {
        name() { return this.$attrs.name || generateRandomString(6); },
        buttons() { return this.$attrs.buttons || []; },
        overlay() { return this.$attrs.overlay || null; },
        dialogAttrs() {
            const { name, buttons, overlay, ...dialogAttrs } = this.$attrs;  // eslint-disable-line no-unused-vars
            return dialogAttrs;
        }
    },
    watch: {
        shown(val) {
            if(val) this.$emit('open');
            else this.$emit('close');
        }
    }
}
</script>
