var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [_vm._t("activator", null, null, { on, attrs })]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.shown,
            callback: function ($$v) {
              _vm.shown = $$v
            },
            expression: "shown",
          },
        },
        "v-dialog",
        _vm.dialogAttrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "v-card",
        [
          _vm.overlay
            ? _c(
                "v-overlay",
                _vm._b(
                  { attrs: { value: _vm.overlay.shown } },
                  "v-overlay",
                  _vm.overlay.attrs || {},
                  false
                ),
                [_vm._t("overlay")],
                2
              )
            : _vm._e(),
          _c("v-card-title", [_vm._t("title")], 2),
          _vm._t("body"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._l(_vm.buttons, function (btn, i) {
                return _c(
                  "v-btn",
                  _vm._g(
                    _vm._b(
                      {
                        key: `dialog-${_vm.name}-action-${i}`,
                        domProps: { textContent: _vm._s(btn.label) },
                      },
                      "v-btn",
                      btn.attrs,
                      false
                    ),
                    btn.on
                  )
                )
              }),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }